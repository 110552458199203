<template>
  <div>
    <el-dialog
      custom-class=""
      width="800px"
      title="新增项目"
      :visible.sync="dialogVisible"
    >
        <el-form label-width="80px" :model="form" ref="addform">
          <el-form-item label="选择项目" :rules="[{ type: 'array', required: true, message: '请选择表单项目', trigger: 'change' }]" prop="communityIds">
              <chosen-list-panel :list.sync="form.communityIds" @select="isShow = true" />
          </el-form-item>
        </el-form>
       <span slot="footer" class="dialog-footer">
        <v-button class="cancelBtn"  type="default" @click="dialogVisible = false" >取 消</v-button>
        <v-button type="primary" @click="onSave">确 定</v-button>
       </span>

    </el-dialog>
    <multi-select
      title="项目列表"
      :isShow.sync="isShow"
      :searchUrl="getCommunityListURL"
      :headers="communityTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.communityIds"
      :handleData="handleData"
    >
      <template #searchSlot>
        <v-input v-model="searchParams.communityName" label="项目名称" />
        <v-select v-model="searchParams.communityStatus" :options="communityStatusOps" label="项目状态"/>
        <v-select v-model="searchParams.communityStage" :options="communityStageOps" label="项目阶段"/>
      </template>
    </multi-select>
  </div>
</template>

<script>
import { ChosenListPanel, MultiSelect } from 'components/bussiness'
import { getCommunityListURL, getCommunityStageOpsURL, addCommunityURL } from './api'
import { setCommunityStatusOps, communityStatusMap } from './map'
export default {
  name: 'add-cmmunity-dialog',
  props: {
    value: false,
    formId: ''
  },
  components: {
    ChosenListPanel,
    MultiSelect
  },
  data () {
    return {
      dialogVisible: this.value,
      form: {
        communityIds:[]
      },
      isShow: false,
      getCommunityListURL,
      communityStatusOps: setCommunityStatusOps(1),
      communityStageOps: [{
        text: '全部',
        value: undefined
      }],
      searchParams: {
        communityName: '',
        communityStatus: 0,
        communityStage: undefined,
        isNormal: 1
      },
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        }, {
          prop: 'communityStatusText',
          label: '项目状态'
        }, {
          prop: 'communityStage',
          label: '项目阶段'
        }, {
          prop: 'regionName',
          label: '所属公司'
        }
      ],
      responseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      }
    }
  },
  methods: {
    async getCommunityStageOps () {
      const { status, data } = await this.$axios.get(getCommunityStageOpsURL)
      if (status === '100') {
        if (Array.isArray(data) && data.length) {
          data.forEach(item => {
            this.communityStageOps.push({
              text: item.value,
              value: item.id
            })
          })
        }
      }
    },
    handleData (data) {
      data.communityStatusText = communityStatusMap[data.communityStatus]
    },
    onSave () {
      let _this_ = this
      this.$refs['addform'].validate((valid) => {
        if (valid) {
          let postData = {
            communityIds: this.form.communityIds.map(item => item.id),
            id: this.formId
          }
          this.$axios.post(addCommunityURL, postData).then(res => {
            if (res.status === 100) {
              _this_.$message({
                type: 'success',
                message: '新增成功',
                center: true
              })
              _this_.dialogVisible = false
              _this_.$refs.addform.resetFields()
              _this_.$emit('refresh')
            }
          })
        }
      })
    }
  },
  watch: {
    value (newValue) {
      this.dialogVisible = newValue
      this.$emit('change', newValue)
      if (newValue) {
        if (this.communityStageOps.length < 2) {
          this.getCommunityStageOps()
        }
      }
    },
    dialogVisible (newValue) {
      // v-model event
      this.$emit('input', newValue)
    }
  }

}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display:flex;
  justify-content: flex-end;
  .cancelBtn {
    margin-right:10px;
  }
}
</style>
