var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CustomFormList" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          isMultiSelect: "",
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.createForm },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "表单名称" },
                  model: {
                    value: _vm.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "name", $$v)
                    },
                    expression: "searchParams.name",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "发布端", options: _vm.publishTypeOps },
                  model: {
                    value: _vm.searchParams.publishType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "publishType", $$v)
                    },
                    expression: "searchParams.publishType",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "表单类型", options: _vm.formTypeOps },
                  model: {
                    value: _vm.searchParams.formType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "formType", $$v)
                    },
                    expression: "searchParams.formType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "创建人" },
                      model: {
                        value: _vm.searchParams.inuser,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "inuser", $$v)
                        },
                        expression: "searchParams.inuser",
                      },
                    },
                    "v-select2",
                    _vm.updateUserParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "表单状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "有效时间",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.beginTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "beginTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "beginTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "预览", type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.previewForm(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "查看", type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.viewForm(scope.row)
                    },
                  },
                }),
                [0].includes(scope.row.status)
                  ? _c("v-button", {
                      attrs: {
                        text: "编辑",
                        permission: "update",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateForm(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                [0, 1, 3].includes(scope.row.status)
                  ? _c("v-button", {
                      attrs: {
                        text: "设计",
                        permission: "design",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.designForm(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _c("v-button", {
                  attrs: { text: "复制", permission: "add", type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.copyForm(scope.row)
                    },
                  },
                }),
                [0].includes(scope.row.status)
                  ? _c("v-button", {
                      attrs: {
                        text: "发布",
                        permission: "publish",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.publishForm(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                [1].includes(scope.row.status)
                  ? _c("v-button", {
                      attrs: {
                        text: "停用",
                        permission: "start",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.stopForm(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                [3].includes(scope.row.status)
                  ? _c("v-button", {
                      attrs: {
                        text: "启用",
                        permission: "start",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.startForm(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                [1].includes(scope.row.hasPublished)
                  ? _c("v-button", {
                      attrs: {
                        text: "结果",
                        permission: "result",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.viewRecord(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                [0, 2, 3].includes(scope.row.status)
                  ? _c("v-button", {
                      attrs: {
                        text: "删除",
                        permission: "delete",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteForm(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                [1].includes(scope.row.status)
                  ? _c("v-button", {
                      attrs: {
                        text: "分享",
                        permission: "share",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.viewURL(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                [1].includes(scope.row.status)
                  ? _c("v-button", {
                      attrs: {
                        text: "新增项目",
                        permission: "addCommunity",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addCommunityClick(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                [1].includes(scope.row.status)
                  ? _c("v-button", {
                      attrs: {
                        text: "短信通知",
                        permission: "messageNotify",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.messageNotify(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                [1].includes(scope.row.status) &&
                [2, 3].includes(scope.row.publishType)
                  ? _c("v-button", {
                      attrs: {
                        text: "创建应用",
                        permission: "addApp",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addApp(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    text: "批量发布",
                    permission: "publish",
                    disabled: scope.selectedData.ids.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchOperation(1, scope.selectedData)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: {
                    text: "批量停用",
                    permission: "start",
                    disabled: scope.selectedData.ids.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchOperation(2, scope.selectedData)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: {
                    text: "批量删除",
                    permission: "delete",
                    disabled: scope.selectedData.ids.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchOperation(3, scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "design-dialog-container",
          style: { display: _vm.designDialogDisplay },
        },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                "custom-class": "design-dialog",
                title: "表单设计器",
                fullscreen: "",
                "modal-append-to-body": false,
                modal: false,
                visible: _vm.designDialogVisible,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.designDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "design-dialog-content remain-form-design" },
                [
                  _c(
                    "fm-making-form",
                    {
                      ref: "makingForm",
                      attrs: {
                        "basic-fields": _vm.basicFields,
                        "advance-fields": _vm.advanceFields,
                        "layout-fields": _vm.layoutFields,
                        customComponents: _vm.customComponents,
                        customParams: _vm.customParams,
                        clearable: "",
                        preview: "",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "action" },
                        [
                          _c("v-button", {
                            staticClass: "button-save",
                            attrs: {
                              icon: "el-icon-check",
                              text: "保存",
                              type: "text",
                            },
                            on: { click: _vm.getJSON },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "viewURL-dialog",
            width: "600",
            title: _vm.viewURLDialogTitle,
            visible: _vm.viewURLDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.viewURLDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "viewURL-dialog-content" },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "144px" } },
                [
                  _c("el-form-item", { attrs: { label: "填写页地址：" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.viewSubmitUrl) + "\n        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "viewURL-dialog-content-button-copy" },
                    [
                      _c("v-button", {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.viewSubmitUrl,
                            expression: "viewSubmitUrl",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                        attrs: { text: "复制" },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "列表页地址：" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.viewListUrl) + "\n        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "viewURL-dialog-content-button-copy" },
                    [
                      _c("v-button", {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.viewListUrl,
                            expression: "viewListUrl",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                        attrs: { text: "复制" },
                      }),
                    ],
                    1
                  ),
                  !_vm.viewURLPublishType || _vm.viewURLPublishType !== 1
                    ? _c(
                        "div",
                        [
                          _vm.userType != "101"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "选择项目：" } },
                                [
                                  _c("v-select", {
                                    staticClass:
                                      "viewURL-dialog-content-select",
                                    attrs: {
                                      options: _vm.communityOps,
                                      placeholder: "请选择项目",
                                      width: 230,
                                      filterable: "",
                                    },
                                    on: { change: _vm.communityChange },
                                    model: {
                                      value: _vm.communityId,
                                      callback: function ($$v) {
                                        _vm.communityId = $$v
                                      },
                                      expression: "communityId",
                                    },
                                  }),
                                  _c("v-button", {
                                    attrs: {
                                      text: "生成二维码",
                                      disabled: !_vm.communityId,
                                    },
                                    on: { click: _vm.showQrcode },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { label: "微信分享二维码：" } },
                            [
                              _vm.viewURLDialogVisible && _vm.qrcodeVisible
                                ? _c("div", {
                                    staticClass:
                                      "viewURL-dialog-content-qrcode",
                                    attrs: { id: _vm.qrcodeWX },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "短信通知", visible: _vm.messageNotifyShow },
          on: {
            "update:visible": function ($event) {
              _vm.messageNotifyShow = $event
            },
            closed: function ($event) {
              _vm.messageMobileNums = ""
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mobile-num-wrapper" },
            [
              _c("div", { staticClass: "mobile-label-wrapper" }, [
                _c("span", { staticClass: "mobile-label" }, [
                  _c(
                    "span",
                    { staticStyle: { color: "red", "font-size": "15px" } },
                    [_vm._v("* ")]
                  ),
                  _vm._v("绑定手机号:"),
                ]),
                _c("span", { staticClass: "mobile-label-tip" }, [
                  _vm._v("注:下列手机号将收到表单结果的短信通知。"),
                ]),
              ]),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  maxlength: "24000",
                  placeholder: "请以11位数字格式输入手机号，并用英文逗号分隔",
                },
                model: {
                  value: _vm.messageMobileNums,
                  callback: function ($$v) {
                    _vm.messageMobileNums = $$v
                  },
                  expression: "messageMobileNums",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.messageNofitySend },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("add-community-dialog", {
        attrs: { formId: _vm.currentFormId },
        on: { refresh: _vm.refresh },
        model: {
          value: _vm.addCommunityVisible,
          callback: function ($$v) {
            _vm.addCommunityVisible = $$v
          },
          expression: "addCommunityVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }