<template>
  <div class="CustomFormList">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      isMultiSelect
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="createForm" />
      </template>
      <template #searchSlot>
        <v-input label="表单名称" v-model="searchParams.name" />
        <v-select label="发布端" v-model="searchParams.publishType" :options="publishTypeOps" />
        <v-select label="表单类型" v-model="searchParams.formType" :options="formTypeOps" />
        <v-select2 label="创建人" v-model="searchParams.inuser" v-bind="updateUserParams" />
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams" />
        <v-select label="表单状态" v-model="searchParams.status" :options="statusOps" />
        <v-datepicker label="有效时间" type="rangedatetimer" :startTime.sync="searchParams.beginTime" :endTime.sync="searchParams.endTime" />
      </template>
      <template #operateSlot="scope">
        <v-button text="预览" type="text" @click="previewForm(scope.row)" />
        <v-button text="查看" type="text" @click="viewForm(scope.row)" />
        <v-button v-if="[0].includes(scope.row.status)" text="编辑" permission="update" type="text" @click="updateForm(scope.row)" />
        <v-button v-if="[0, 1, 3].includes(scope.row.status)" text="设计" permission="design" type="text" @click="designForm(scope.row)" />
        <v-button text="复制" permission="add" type="text" @click="copyForm(scope.row)" />
        <v-button v-if="[0].includes(scope.row.status)" text="发布" permission="publish" type="text" @click="publishForm(scope.row)" />
        <v-button v-if="[1].includes(scope.row.status)" text="停用" permission="start" type="text" @click="stopForm(scope.row)" />
        <v-button v-if="[3].includes(scope.row.status)" text="启用" permission="start" type="text" @click="startForm(scope.row)" />
        <v-button v-if="[1].includes(scope.row.hasPublished)" text="结果" permission="result" type="text" @click="viewRecord(scope.row)" />
        <v-button v-if="[0, 2, 3].includes(scope.row.status)" text="删除" permission="delete" type="text" @click="deleteForm(scope.row)" />
        <v-button v-if="[1].includes(scope.row.status)" text="分享" permission="share" type="text" @click="viewURL(scope.row)" />
        <v-button v-if="[1].includes(scope.row.status)" text="新增项目" permission="addCommunity" type="text" @click="addCommunityClick(scope.row)" />
        <v-button v-if="[1].includes(scope.row.status)" text="短信通知" permission="messageNotify" type="text" @click="messageNotify(scope.row)" />
        <v-button v-if="[1].includes(scope.row.status) && [2,3].includes(scope.row.publishType)" text="创建应用" permission="addApp" type="text" @click="addApp(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button text="批量发布" permission="publish" :disabled="scope.selectedData.ids.length === 0" @click="batchOperation(1, scope.selectedData)" />
        <v-button text="批量停用" permission="start" :disabled="scope.selectedData.ids.length === 0" @click="batchOperation(2, scope.selectedData)" />
        <v-button text="批量删除" permission="delete" :disabled="scope.selectedData.ids.length === 0" @click="batchOperation(3, scope.selectedData)" />
      </template>
    </list>
    <div class="design-dialog-container" :style="{ 'display': designDialogDisplay }">
      <el-dialog custom-class="design-dialog"
        title="表单设计器"
        fullscreen
        :modal-append-to-body="false"
        :modal="false"
        :visible.sync="designDialogVisible"
        :before-close="handleClose"
      >
        <div class="design-dialog-content remain-form-design">
            <fm-making-form ref="makingForm" :basic-fields="basicFields" :advance-fields="advanceFields" :layout-fields="layoutFields" :customComponents="customComponents" :customParams="customParams" clearable preview>
              <template slot="action">
                <v-button class="button-save" icon="el-icon-check" text="保存" type="text" @click="getJSON" />
              </template>
            </fm-making-form>
        </div>
      </el-dialog>
    </div>
    <el-dialog
      custom-class="viewURL-dialog"
      width="600"
      :title="viewURLDialogTitle"
      :visible.sync="viewURLDialogVisible"
    >
      <div class="viewURL-dialog-content">
        <el-form label-width="144px">
          <el-form-item label="填写页地址：">
            {{ viewSubmitUrl  }}
          </el-form-item>
          <div class="viewURL-dialog-content-button-copy">
            <v-button
              text="复制"
              v-clipboard:copy="viewSubmitUrl"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            />
          </div>
          <el-form-item label="列表页地址：">
            {{ viewListUrl }}
          </el-form-item>
          <div class="viewURL-dialog-content-button-copy">
            <v-button
              text="复制"
              v-clipboard:copy="viewListUrl"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            />
          </div>
          <div v-if="!viewURLPublishType || viewURLPublishType !== 1">
            <el-form-item label="选择项目：" v-if="userType != '101'">
              <v-select class="viewURL-dialog-content-select" v-model="communityId" :options="communityOps" placeholder="请选择项目" :width="230" filterable @change="communityChange" />
              <v-button text="生成二维码" :disabled="!communityId" @click="showQrcode" />
            </el-form-item>
            <el-form-item label="微信分享二维码：">
              <div class="viewURL-dialog-content-qrcode" :id="qrcodeWX" v-if="viewURLDialogVisible && qrcodeVisible"></div>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-dialog>
      <el-dialog title="短信通知" :visible.sync="messageNotifyShow" @closed="messageMobileNums = ''">
        <div class="mobile-num-wrapper">
          <div class="mobile-label-wrapper">
            <span class="mobile-label"><span style="color: red; font-size: 15px;">* </span>绑定手机号:</span>
            <span class="mobile-label-tip">注:下列手机号将收到表单结果的短信通知。</span>
          </div>
          <el-input
            type="textarea"
            :rows="5"
            maxlength="24000"
            placeholder="请以11位数字格式输入手机号，并用英文逗号分隔"
            v-model="messageMobileNums">
          </el-input>
        </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="messageNofitySend">保 存</el-button>
      </div>
    </el-dialog>
    <add-community-dialog v-model="addCommunityVisible" :formId="currentFormId" @refresh="refresh"/>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { vUploader } from 'components/control'
import AddCommunityDialog from './AddCommunityDialog'
import { queryListURL, getUserListURL, publishURL, stopURL, startURL, deleteURL, designURL, copyFormInfoURL, queryFormShareWXURL, queryDesignContentURL, batchPublishURL, batchStopURL, batchDeleteURL, saveMessageNotifyInfoURL, getMessageNotifyInfoUrl } from './api'
import { setPublishTypeOps, publishTypeMap, setFormTypeOps, formTypeMap, setStatusOps, statusMap } from './map'
import Vue from 'vue'
import { Container, Main, Aside, Header, Footer, Checkbox, CheckboxGroup, Radio, Switch, Rate, TimePicker, DatePicker } from 'element-ui'
import FormMaking from 'remain-form-making'
import 'remain-form-making/dist/FormMaking.css'
import VueClipboard from 'vue-clipboard2'
import QRCode from 'qrcodejs2'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.use(Container)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(Switch)
Vue.use(Rate)
Vue.use(TimePicker)
Vue.use(DatePicker)
Vue.use(FormMaking)

export default {
  name: 'CustomFormList',
  data () {
    return {
      baseURL: '',
      qrcodeWX: 'qrcode_wx',
      messageNotifyShow: false,
      messageMobileNums: '',
      shareData: {
        formId: undefined
      },
      communityId: undefined,
      communityOps: [],
      designDialogVisible: false,
      desingId: '',
      desingRow: {},
      customParams: {},
      searchUrl: queryListURL,
      messageNotifyFormId: '',
      updateUserParams: {
        searchUrl: getUserListURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      communityParams,
      publishTypeOps: setPublishTypeOps(1),
      formTypeOps: setFormTypeOps(1),
      statusOps: setStatusOps(1),
      searchParams: {
        name: '',
        publishType: undefined,
        formType: undefined,
        inuser: '',
        communityId: '',
        status: undefined,
        beginTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'name',
          label: '表单名称'
        },
        {
          prop: 'publishType',
          label: '发布端',
          formatter (row) {
            return publishTypeMap[row.publishType]
          }
        },
        {
          prop: 'formType',
          label: '表单类型',
          formatter (row) {
            return formTypeMap[row.formType]
          }
        },
        {
          prop: 'inuserName',
          label: '创建人'
        },
        {
          prop: 'communityListText',
          label: '所属项目',
          formatter (row) {
            const communityIds = []
            row.communityIds.forEach(item => {
              communityIds.push(item.name)
            })
            return communityIds.join('，')
          }
        },
        {
          prop: 'statusText',
          label: '表单状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'validityTime',
          label: '表单截止时间'
        }
      ],
      basicFields: ['input', 'textarea', 'select', 'radio', 'checkbox', 'number', 'imgupload', 'time', 'date', 'rate', 'switch', 'text'],
      advanceFields: ['fileupload'],
      layoutFields: ['grid', 'table'],
      customComponents: {
        upload: vUploader
      },
      designDialogDisplay: 'block',
      viewURLDialogVisible: false,
      qrcodeVisible: true,
      viewURLDialogTitle: '',
      viewSubmitUrl: '',
      addCommunityVisible: false,
      currentFormId: undefined,
      viewListUrl: '',
      viewURLPublishType: undefined
    }
  },
  components: {
    AddCommunityDialog
  },
  created () {
    this.getBaseURL()
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    },
    // 平台应用库权限读取
    applicationLibraryPermission () {
      // 权限读取
      let permission = this.$store.state.menuStore.menuPermission['applicationLibraryPlatform']
      return permission
    }
  },
  methods: {
    createForm () {
      this.$router.push({
        name: 'customFormForm'
      })
    },
    previewForm (row) {
      const id = row.id
      this.designDialogDisplay = 'none'
      this.queryDesignConten(id, () => {
        this.$refs.makingForm.handlePreview(true)
      })
    },
    viewForm (row) {
      this.$router.push({
        name: 'customFormForm',
        query: {
          id: row.id,
          view: 1
        }
      })
    },
    updateForm (row) {
      this.$router.push({
        name: 'customFormForm',
        query: {
          id: row.id
        }
      })
    },
    checkBatchData (selected, allowStatus, message) {
      const data = selected.data
      for (let i = 0; i < data.length; i++) {
        if (!allowStatus.includes(data[i].status)) {
          this.$message({
            type: 'warning',
            message,
            center: true
          })
          return false
        }
      }
      return true
    },
    async batchOperation (type, selected) {
      let url = ''
      let allowStatus = []
      let message = ''
      if (type === 1) {
        url = batchPublishURL
        allowStatus = [0]
        message = '表单状态错误，无法批量发布'
      } else if (type === 2) {
        url = batchStopURL
        allowStatus = [1]
        message = '表单状态错误，无法批量停用'
      } else if (type === 3) {
        url = batchDeleteURL
        allowStatus = [0, 2, 3]
        message = '表单状态错误，无法批量删除'
      }
      const checkResult = this.checkBatchData(selected, allowStatus, message)
      if (checkResult) {
        if (type === 3) {
          const result = await this.$confirm('确认删除？')
          if (!result) {
            return
          }
        }
        let dataObjectArray = []
        selected.data.forEach(item => {
          dataObjectArray.push(this.getDataObject(item))
        })

        const ids = selected.ids
        const params = {
          ids,
          dataObject: dataObjectArray.join(';')
        }
        const { status } = await this.$axios.post(url, params)
        if (status === 100) {
          this.$message.success('操作成功')
          this.$refs.list.searchData()
        }
      }
    },
    async queryDesignConten (id, cb) {
      const url = `${queryDesignContentURL}?id=${id}`
      const { status, data } = await this.$axios.get(url)
      if (status === 100) {
        this.designDialogVisible = true
        this.desingId = id
        this.$nextTick(() => {
          try {
            if (data) {
              const designData = JSON.parse(data)
              // 兼容之前老的控件
              for (let index = 0; index < designData.list.length; index++) {
                const element = designData.list[index]
                if (element.type === 'input') {
                  if (element.options.dataType === 'integer') {
                    element.options.pointPosition = 0
                  } else if (element.options.dataType === 'float') {
                    element.options.pointPosition = 6
                  } else if (element.options.dataType === 'number') {
                    if (element.options.pointPosition === undefined) {
                      element.options.pointPosition = 6
                    }
                  } else {
                    if (element.options.length === undefined) {
                      element.options.length = element.options.maxlength
                    }
                  }
                } else if (element.type === 'textarea') {
                  if (element.options.length === undefined) {
                    element.options.length = element.options.maxlength
                  }
                }
              }
              this.$refs.makingForm.setJSON(designData)
            } else {
              this.$refs.makingForm.handleClear()
            }
          } catch {}
          cb && cb()
        })
      }
    },
    designForm (row) {
      const id = row.id
      this.desingRow = row
      this.designDialogDisplay = 'block'
      // 对子表控件做限制，只有在发布端为PC端时才显示
      // if (row.publishType === 1) {
      //   this.layoutFields = ['grid', 'table']
      // } else {
      //   this.layoutFields = ['grid']
      // }
      this.customParams.publishPlatform = row.publishType.toString()
      this.queryDesignConten(id)
    },
    async copyForm (row) {
      const id = row.id
      const url = `${copyFormInfoURL}?formId=${id}&dataObject=${this.getDataObject(row)}`
      const { status } = await this.$axios.post(url)
      if (status === 100) {
        this.$message.success('复制成功')
        this.$refs.list.searchPanelSearchData()
      }
    },
    async publishForm (row) {
      const id = row.id
      const url = `${publishURL}?id=${id}&dataObject=${this.getDataObject(row)}`
      const { status } = await this.$axios.post(url)
      if (status === 100) {
        this.$message.success('发布成功')
        this.$refs.list.searchData()
      }
    },
    getDataObject (row) {
      let dataObject = `${row.name}`
      let communityNames = row.communityIds.map(item => item.name).join(',')
      if (communityNames) {
        dataObject += `-${communityNames}`
      }
      return dataObject
    },
    async stopForm (row) {
      const id = row.id
      const url = `${stopURL}?id=${id}&dataObject=${this.getDataObject(row)}`
      const { status } = await this.$axios.post(url)
      if (status === 100) {
        this.$message.success('停用成功')
        this.$refs.list.searchData()
      }
    },
    async startForm (row) {
      const id = row.id
      const url = `${startURL}?id=${id}&dataObject=${this.getDataObject(row)}`
      const { status } = await this.$axios.post(url)
      if (status === 100) {
        this.$message.success('启用成功')
        this.$refs.list.searchData()
      }
    },
    async deleteForm (row) {
      const result = await this.$confirm('确认删除？')
      if (result) {
        const id = row.id
        const url = `${deleteURL}?id=${id}&dataObject=${this.getDataObject(row)}`
        const { status } = await this.$axios.post(url)
        if (status === 100) {
          this.$message.success('删除成功')
          this.$refs.list.searchData()
        }
      }
    },
    viewRecord (row) {
      this.$router.push({
        name: 'customFormRecordList',
        query: {
          formId: row.id,
          source: 'customFormList'
        }
      })
    },
    showQrcode () {
      this.qrcodeVisible = false
      this.$nextTick(() => {
        this.qrcodeVisible = true
        const communityId = this.communityId
        this.getFormShareWX(communityId)
      })
    },
    async getBaseURL () {
      const { status, data } = await this.$axios.get(queryFormShareWXURL)
      if (status === 100) {
        this.baseURL = data
      }
    },
    async getFormShareWX (communityId) {
      const { formId } = this.shareData
      const busiUrl = encodeURIComponent(`${this.baseURL}/h5new/public/middle.html?path=customForm&clientType=user&formId=${formId}&communityId=${communityId}&outer=1`)
      const text = `${this.baseURL}/external/mp/snsapiUserInfo?busiUrl=${busiUrl}`
      this.$nextTick(() => {
        new QRCode(this.qrcodeWX, { // eslint-disable-line
          text,
          width: 230,
          height: 230,
          correctLevel: 3
        })
      })
    },
    communityChange (val) {
      this.qrcodeVisible = false
      this.$nextTick(() => {
        this.qrcodeVisible = true
      })
    },
    viewURL (row) {
      const id = row.id
      // customFormList
      const submiturl = `${this.baseURL}/h5new/user/home.html#/customForm`
      const listUrl = `${this.baseURL}/h5new/user/home.html#/customFormList`
      const message = `${submiturl}?formId=${id}`
      this.viewURLDialogTitle = row.name
      this.viewSubmitUrl = message
      this.viewListUrl = `${listUrl}?formId=${id}`
      this.viewURLDialogVisible = true
      this.viewURLPublishType = row.publishType
      this.shareData.formId = id
      if (this.userType === '101') {
        const communityId = this.$store.state.userInfo.communityId
        this.getFormShareWX(communityId)
      } else {
        const communityOps = []
        row.communityIds.forEach(item => {
          communityOps.push({
            text: item.name,
            value: item.id
          })
        })
        this.communityId = undefined
        this.communityOps = communityOps
      }
    },
    async getJSON () {
      const id = this.desingId
      const formJson = this.$refs.makingForm.getJSON()
      if (formJson && formJson.list && formJson.list.length) {
        for (let i = 0; i < formJson.list.length; i++) {
          const item = formJson.list[i]
          if (['select', 'radio', 'checkbox'].includes(item.type)) {
            if (item.options && item.options.options && item.options.options.length) {
              for (let index = 0; index < item.options.options.length; index++) {
                const option = item.options.options[index]
                if ((option.label !== undefined && option.label.trim() === '') || (option.value !== undefined && option.value.trim() === '')) {
                  const message = `${item.name}：控件选项不能有空值`
                  this.$message.error(message)
                  return
                }
              }
            }
            // 多选框, 选项默认值
            if (['checkbox'].includes(item.type)) {
              if (item.options && item.options.defaultValue && Array.isArray(item.options.defaultValue)) {
                const defaultValue = []
                item.options.defaultValue.forEach((value) => {
                  const optionIndex = item.options.options.findIndex((option) => {
                    return option.value === value
                  })
                  if (optionIndex !== -1) {
                    defaultValue.push(value)
                  }
                })
                item.options.defaultValue = defaultValue
              }
            }
          }
          // 子表单
          if (['table'].includes(item.type)) {
            if (item.tableColumns && item.tableColumns.length) {
              const showinlist = []
              item.tableColumns.forEach((value) => {
                if (value.options.showinlist) {
                  showinlist.push(value)
                }
              })
              if (showinlist.length == 0) {
                const message = `${item.name}：表格字段至少设置一个在列表显示`
                this.$message.error(message)
                return
              }
            } else {
              const message = `${item.name}：表格字段不能为空`
              this.$message.error(message)
              return
            }
          }
        }
      }

      let dataObject = this.getDataObject(this.desingRow)
      const params = {
        id,
        formJson,
        dataObject
      }
      const { status, data } = await this.$axios.post(designURL, params)
      if (status === 100) {
        this.$message.success('保存成功')
        this.designDialogVisible = false
      }
    },
    onCopy () {
      this.$message.success('复制成功')
      this.viewURLDialogVisible = false
    },
    onError () {
      this.$message('您的浏览器不支持自动复制功能')
    },
    async handleClose (done) {
      const result = await this.$confirm('表单设计还没有保存，是否返回表单管理列表？')
      if (result) {
        done()
      }
    },
    addCommunityClick (row) {
      this.currentFormId = row.id
      this.addCommunityVisible = true
    },
    async messageNotify (row) {
      this.messageNotifyShow = true
      this.messageNotifyFormId = row.id
      const url = `${getMessageNotifyInfoUrl}?formId=${row.id}`
      const { status, data } = await this.$axios.get(url)
      if (status === 100 && data) {
        this.messageMobileNums = data
      }
    },
    async messageNofitySend (row) {
      var numList = []
      this.messageMobileNums.split(',').forEach((value, index, array) => {
        // 首位是1的纯数字 + 11位 + 没有重复
        if (/^[1][0-9]*$/.test(value) && value.length === 11 && numList.indexOf(value) === -1) {
          numList.push(value)
        }
      })
      if (numList.length < 1) {
        this.$message('请输入合法手机号')
        return
      }
      const formId = this.messageNotifyFormId
      const mobileNums = numList.join(',')
      const params = {
        formId,
        mobileNums
      }
      const { status } = await this.$axios.post(saveMessageNotifyInfoURL, params)
      if (status === 100) {
        this.$message.success('保存成功')
        this.messageNotifyShow = false
      }
    },
    refresh () {
      this.$refs.list.searchData()
    },

    addApp (row) {
      if (this.applicationLibraryPermission && this.applicationLibraryPermission.add === 1) {
        // 有新增权限
        const url = `${this.baseURL}/h5new/user/home.html#/customForm?formId=${row.id}`
        this.$router.push({
          name: 'applicationLibraryPlatformForm',
          query: {
            appURL: url
          }
        })
      } else {
        this.$message.error('请先获取平台应用库的新增权限')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.CustomFormList {
  ::v-deep {
    .design-dialog {
      display: flex;
      flex-direction: column;
      text-align: start;
      .el-dialog__body {
        flex: 1;
        padding: 0;
        .design-dialog-content {
          height: 100%;
          .button-save {
            font-size: 14px;
            margin-right: 10px;
          }
        }
      }
    }
    .viewURL-dialog {
      .viewURL-dialog-content {
        text-align: left;
        .viewURL-dialog-content-button-copy {
          text-align: center;
          margin: 10px 0 20px 0;
        }
        .viewURL-dialog-content-select {
          margin-right: 20px;
        }
        .viewURL-dialog-content-qrcode {
          width: 230px;
          height: 230px;
        }
      }
    }
  }
  .mobile-num-wrapper{
    display: flex;
    flex-direction: column;
    .mobile-label-wrapper{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
      .mobile-label{
        font-size: 18px;
      }
      .mobile-label-tip{
        font-size: 12px;
        color: red;
      }
    }
    .el-textarea{
      width: 100%
    }
  }
}
</style>
