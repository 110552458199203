var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "",
            width: "800px",
            title: "新增项目",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: { "label-width": "80px", model: _vm.form },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择项目",
                    rules: [
                      {
                        type: "array",
                        required: true,
                        message: "请选择表单项目",
                        trigger: "change",
                      },
                    ],
                    prop: "communityIds",
                  },
                },
                [
                  _c("chosen-list-panel", {
                    attrs: { list: _vm.form.communityIds },
                    on: {
                      "update:list": function ($event) {
                        return _vm.$set(_vm.form, "communityIds", $event)
                      },
                      select: function ($event) {
                        _vm.isShow = true
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "v-button",
                {
                  staticClass: "cancelBtn",
                  attrs: { type: "default" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "v-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "项目列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getCommunityListURL,
          headers: _vm.communityTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.communityIds,
          handleData: _vm.handleData,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.searchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityName", $$v)
                    },
                    expression: "searchParams.communityName",
                  },
                }),
                _c("v-select", {
                  attrs: { options: _vm.communityStatusOps, label: "项目状态" },
                  model: {
                    value: _vm.searchParams.communityStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStatus", $$v)
                    },
                    expression: "searchParams.communityStatus",
                  },
                }),
                _c("v-select", {
                  attrs: { options: _vm.communityStageOps, label: "项目阶段" },
                  model: {
                    value: _vm.searchParams.communityStage,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStage", $$v)
                    },
                    expression: "searchParams.communityStage",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }